@font-face {
  font-family: Prompt;
  src: url("../../public/fonts/Prompt-Medium.ttf");
}

@font-face {
  font-family: Prompt;
  src: url("../../public/fonts/Prompt-Bold.ttf");
  font-weight: bold;
}

* {
  font-family: Prompt, serif;
}

.outlet {
  height: 100dvh;
  overflow-x: auto;
}

.criteria-flex {
  display: flex;
  gap: 0.5rem;

  .control {
    flex-grow: 1;
  }

  .button {
    display: flex;
    gap: 0.5rem;
    height: 38px;
    position: relative;
    top: 24px;

    button {
      white-space: nowrap;
    }
  }
}

.criteria-button {
  display: flex;
  gap: 0.5rem;
  height: 38px;
  position: relative;

  @include media-breakpoint-down(sm) {
    top: 0;
  }

  @include media-breakpoint-up(sm) {
    top: 32px;
  }

  button {
    white-space: nowrap;
  }
}

.button-list-form {
  position: relative;
  top: 32px;
}

.cursor-pointer {
  cursor: pointer;
}

.warning-color {
  color: $danger;
}

.bg-switch {
  border-radius: 8px;
  background: $primary-light;

  &:hover {
    background: #7b4141;
  }
}

.disabled {
  &.css-3iigni-container {
    .css-16xfy0z-control {
      background-color: #e9ecef;
    }
  }
}

.none-border-right {
  border-right: none;
}

.form-label ~ .fatime-noborder-nobg {
  border-left: none;
  background: none;
  position: absolute;
  // right: 40px;
  // top: 24px;
  // height: 38px;
  border: 0;
  z-index: 999;
}

.fatime-noborder-nobg {
  border-left: none;
  background: none;
  position: absolute;
  right: 40px;
  top: 0;
  height: 38px;
  border: 0;
}

.popover {
  width: 775px;
  max-width: 1000px;
}

.fs-7 {
  font-size: 0.85rem !important;
}

.list-style-none {
  list-style: none;
}

.fs-8 {
  font-size: 3.5rem !important;
}

.overflow-scroll-popover {
  overflow-y: scroll !important;
  max-height: 150px !important;
}

.text-ellipsis {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-bottom: 0px;
}

.iframe-custom {
  width: 100%;
  height: 40%;
}

.responsive-iframe {
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 100%;
}

.collapse-video {
  height: 80px;
  width: 20px;
  left: 24.82%;
  top: 54%;
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  color: $primary;
  border-radius: 0 0.5rem 0.5rem 0;
  box-shadow: 5px 0 5px -2px #888;
  background-color: $primary;
  padding-left: 14px;
  padding-right: 14px;
  color: white;
  cursor: pointer;
}

.show-video {
  height: 80px;
  top: 35%;
  width: 20px;
  position: fixed;
  display: flex;
  align-items: center;
  justify-content: center;
  color: $primary;
  border-radius: 0 0.5rem 0.5rem 0;
  box-shadow: 5px 0 5px -2px #888;
  background-color: $primary;
  color: white;
  padding-left: 14px;
  padding-right: 14px;
  cursor: pointer;
}

.text-link {
  text-decoration: underline;
  cursor: pointer;
  color: $link;
}
